<template>
  <section class="success-page">
    <!-- Event snippet for Compra realizada conversion page
    <script type="application/javascript">
      gtag('event', 'conversion', {
      'send_to': 'AW-341109664/VolxCImTkZoZEKDX06IB',
      'transaction_id': ''
  });
    </script> -->

    <span id="party-popper" class="mb-6">
      <vue-lottie :options="lottieOptions" :width="264" :height="246" />
    </span>
    <h4 class="title mb-5">Thank you for booking with us</h4>
    <h4 class="subtitle mt-1 mb-6">
      <p class="mb-2">We can't wait to show you around and make it a memorable experience!</p>
     <p>You'll get an email with you ticket(s) shortly. </p>
    </h4>
  </section>
</template>

<script>
import VueLottie from 'vue-lottie';
export default {
  components: {
        VueLottie,
    },
  data() {
    return {
      lottieOptions: {
                animationData: require('@/assets/animations/success.json'),
                loop: false,
                autoplay: true,
            },
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.success-page {
  text-align: center;
  margin-top: 12vh;
  margin-bottom: 20vh;
}

.title {
  font-family: 'Inter', sans-serif;
  font-size: 1.3rem;
  font-weight: 600
}

.subtitle {
  font-weight: 400;
  color: #0e3d4d;
  font-size: 1.2rem;
}

.icon {
  font-size: 58px;
}
</style>
